.loader-container {
	// height: calc(100vh - 150px);
	.circle {
		height: 100px;
		width: 100px;
		border-radius: 50%;
		animation: custom-loader-anim 2.1s infinite ease-out;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		opacity: 0;
		background: #1592e6;
	}

	.circle.delay-1 {
		animation-delay: 0.7s;
	}

	.circle.delay-2 {
		animation-delay: 1.4s;
	}
}

@keyframes custom-loader-anim {
	from {
		transform: translate(-50%, -50%) scale(0);
		opacity: 1;
	}
	to {
		transform: translate(-50%, -50%) scale(1);
		opacity: 0;
	}
}
